<template>
  <div class="flex flex-col p-2 sm:p-6 bg-lg">
    <div class="flex justify-center items-center">
      <div
        class="
          container
          bg-white
          mt-8
          sm:w-9/12
          md:w-7/12
          lg:w-auto
          rounded
          p-1
          sm:p-5
        "
      >
        <toastSuccess v-if="toastError" :bgToast="'bg-bgk-6'">
          <span slot="icon">
            <IconToastError />
          </span>
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.toastErrorMessage") }}
          </p>
        </toastSuccess>
        <toastSuccess v-if="toast">
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.toastMessage") }}
          </p>
        </toastSuccess>
        <toastSuccess v-if="toastDownloadError" :bgToast="'bg-bgk-6'">
          <span slot="icon">
            <IconToastError />
          </span>
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.downloadErrorMessage") }}
          </p>
        </toastSuccess>
        <div class="p-3 grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div class="lg:hidden grid-cols-1">
            <div class="text-center">
              <img
                class="m-auto"
                src="../../assets/svg/icons/rechargelady.svg"
                alt
              />
              <span class="text-base font-semibold">{{
                $t("rechargeSuccess.titleBill")
              }}</span>
              <br />
              <span class="text-sm">{{
                $t("rechargeSuccess.subtitleBill", {
                  amount: paySuccessData.amount + paySuccessData.fees,
                  meterNumb: paySuccessData.subscriber.reference,
                })
              }}</span>
            </div>
            <button
              @click="goToFirstPurchase"
              class="
                text-white
                mt-4
                text-sm
                bg-radio-100
                h-minput
                hover:bg-compteur-600
                rounded
                px-10
                py-2
                w-full
                focus:outline-none
              "
            >
              {{ $t("rechargeSuccess.newPurchase") }}
            </button>
          </div>

          <div
            class="
              container
              col-span-1
              lg:col-span-3
              border border-kborder-10
              rounded
              shadow
            "
          >
            <div class="flex justify-between items-center">
              <div class="p-3 text-center hidden lg:block">
                <img
                  class="m-auto"
                  src="../../assets/svg/icons/rechargelady.svg"
                  alt
                />
                <span class="text-base font-semibold">{{
                  $t("rechargeSuccess.titleBill")
                }}</span>
                <br />
                <span class="text-sm">{{
                  $t("rechargeSuccess.subtitleBill", {
                    amount: paySuccessData.amount + paySuccessData.fees,
                    meterNumb: paySuccessData.subscriber.reference,
                  })
                }}</span>
              </div>
              <div>
                <Iconarrow class="hidden lg:block" />
              </div>
              <div class="p-4 w-full lg:w-4/6">
                <div class="">
                  <p class="text-xl lg:text-2xl font-bold mb-2">
                    {{ $t("rechargePay.ptFacture") }}
                  </p>
                  <div class="flex flex-col space-y-4 pr-4">
                    <div class="flex flex-col space-y-2">
                      <facture-line
                        v-for="bill in paySuccessData.bills"
                        :key="bill.invoiceNumber"
                        :dateRange="
                          $t('rechargePay.factureOf') + ' ' + bill.period
                        "
                        :amount="bill.amountToBePaid"
                      />
                    </div>
                    <div
                      class="
                        grid
                        lg:grid-flow-row lg:grid-cols-3
                        gap-y-3
                        lg:gap-y-0
                        gap-x-0
                        lg:gap-3
                      "
                    >
                      <icon-title-label
                        :title="$t('rechargePay.kkpFees')"
                        :amount="paySuccessData.fees + ` FCFA`"
                      >
                        <template v-slot:icon>
                          <mtn-icon />
                        </template>
                      </icon-title-label>
                      <icon-title-label
                        title="Total"
                        :amount="
                          paySuccessData.amount + paySuccessData.fees + ` FCFA`
                        "
                      >
                        <template v-slot:icon>
                          <cash />
                        </template>
                      </icon-title-label>
                      <icon-title-label
                        title="ID"
                        :amount="paySuccessData.referenceKkiapay"
                      >
                        <template v-slot:icon>
                          <id />
                        </template>
                      </icon-title-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full justify-center space-x-8">
                <button
                  type="button"
                  @click="downloadBill"
                  class="
                    hidden
                    w-3/12
                    focus:outline-none
                    text-white
                    border
                    h-minput
                    bg-kbutton-1
                    hover:bg-kbutton-2
                    active:border active:border-kbutton-3
                    xl:px-6
                    sm:text-base
                    py-3
                    px-8
                    rounded
                    mt-10
                    mb-20
                    xl:flex
                    justify-center
                    items-center
                  "
                >
                  <Icondownl v-show="!downloadRequest" />
                  <span v-show="!downloadRequest" class="ml-2">{{
                    $t("rechargeSuccess.downloadBtn")
                  }}</span>
                  <LoaderCss class="ml-16" v-show="downloadRequest" />
                </button>
                <a
                  @click="$router.push('/dashboard/pay')"
                  class="
                    cursor-pointer
                    hidden
                    w-3/12
                    focus:outline-none
                    text-white
                    border
                    h-minput
                    hover:bg-kbutton-2
                    active:border active:border-kbutton-3
                    xl:px-6
                    sm:text-base
                    py-3
                    px-8
                    rounded
                    mt-10
                    mb-20
                    xl:flex
                    justify-center
                    items-center
                  "
                  style="background-color: #4661b9"
                >
                  <Icondownl v-show="!downloadRequest" />
                  <span v-show="!downloadRequest" class="ml-2">{{
                    $t("rechargeSuccess.operationBtn")
                  }}</span>
                </a>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import Iconarrow from "../../assets/svg/icons/redarrow.svg?inline";
import IconToastError from "../../assets/svg/icons/toasterror.svg?inline";
import toastSuccess from "../../components/toastComponents/toastSuccess";
// import Iconcopy from "../../assets/svg/icons/copy.svg?inline";
// import Iconshare from "../../assets/svg/icons/share.svg?inline";
import FactureLine from "@/components/FactureLine.vue";
import IconTitleLabel from "../../components/IconTitleLabel.vue";
import MtnIcon from "../../components/Icons/MtnIcon.vue";
import Cash from "../../components/Icons/Cash.vue";
import Id from "../../components/Icons/Id.vue";
import axios from 'axios';
export default {
  components: {
    // IconStsmb,
    // Iconcopy,
    Iconarrow,
    toastSuccess,
    IconToastError,
    FactureLine,
    IconTitleLabel,
    MtnIcon,
    Cash,
    Id,
  },
  data() {
    return {
      submittedMail: false,
      submittedSms: false,
      toast: false,
      toastError: false,
      toastDownloadError: false,
      mail: true,
      downloadRequest: false,
      sendRequest: false,
      rechargeDetails: false,
      mailTosend: "",
      smsTosend: "",
      phoneError: false,
      countryCode: null,
    };
  },
  watch: {
    smsTosend() {
      return (this.phoneError = false);
    },
  },
  computed: {
    paySuccessData() {
      return this.$store.getters.getPaidBillsData;
    },
  },
  methods: {
    countryChanged(country) {
      this.countryCode = country.dialCode;
    },
    sendBillByMail() {},
    sendBillBySms() {},
    billSendMethod() {
      this.mail = !this.mail;
    },
    goToFirstPurchase() {
      this.$store.commit("mutPaymentStep", false);
      this.$store.commit(
        "mutPrevCounterNumber",
        this.paySuccessData.subscriber.reference
      );
      this.$router.push("/recharges");
    },

    downloadBill() {
      axios.get(
          process.env.VUE_APP_API_BASE_URL + `/transactions/download/${this.paySuccessData._id}`
      ).then(response => {
          window.open(response.data, '_blank');
      })
    },
  },

  mounted() {
    this.$store.commit("mutPleaseWaitStep", false);
    localStorage.removeItem("sbeeTransaction");
  },
};
</script>

<style scoped>
.bg-lg {
  background-image: url(../../assets/svg/SBEEA1.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}
@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
  }
}
.elt-flex-sub {
  gap: 40px !important;
}
h1 {
  color: #000;
  font-size: 24px;
  font-weight: 900;
}
.elt-flex {
  display: flex;
  align-items: center;
  gap: 7px;
}
.point-div {
  max-width: 60%;
  margin-left: 20px;
}
.w-100 {
  width: 100%;
}
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid #c6d6ec;
  text-align: left;
}

.vue-tel-input:hover {
  border-color: #a5bce0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #4661b9;
}

.vue-tel-input:focus {
  outline: none !important;
  border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within {
  outline-style: none;
  border-top-left-radius: 0.125rem /* 2px */;
  border-bottom-left-radius: 0.125rem /* 2px */;
}
</style>